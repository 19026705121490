import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { get, isNil } from 'lodash-es';

import { LocumsNestComponent } from '@locumsnest/components/src/lib/core/locums-nest-component';

import { NavItem } from '../../interfaces/nav-item';

@Component({
  selector: 'locumsnest-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuListItemComponent extends LocumsNestComponent {
  @Input() idx: string;
  @Input() item: NavItem;
  @Input() depth: number;
  @Input() menuExpanded: boolean;
  @Input() isFirstElement: boolean;
  @Input() isLastElement: boolean;
  @Input() currentUrl: string;
  @Input() filterCount: number;
  @Input() hospitalOfficerPath: string;
  @Input() filtersPanelExpanded: boolean;
  @Input() nestPanelExpanded: boolean;
  @Input() itemListLength: number;
  @Input() isAgencyPlatform: boolean;

  @Output() toggle = new EventEmitter<string>();
  @Output() clicked = new EventEmitter<string>();
  @Output() filterMenuClicked = new EventEmitter<void>();

  constructor(private router: Router) {
    super();
  }

  trackByItem(_: number, item: NavItem) {
    return item.code;
  }

  onItemSelected(item: NavItem) {
    if (this.currentUrl.split('?')[0] === item.path)
      return this.router.navigateByUrl(this.currentUrl);

    if (!item.hasRightMenu) this.clicked.emit(item.displayName);
    if (item.hasRightMenu && item.code !== 'filters') {
      this.toggle.emit(item.displayName);
    }
    if (item.code === 'settings') {
      return this.router.navigateByUrl(this.hospitalOfficerPath);
    }

    if (item.code === 'filters') {
      return this.filterMenuClicked.emit();
    }

    if (item.path !== null) {
      return this.router.navigateByUrl(item.path);
    }
  }

  isActive(item: NavItem, currentUrl: string): boolean {
    const path = get(item, 'path');

    if (!isNil(path) && !isNil(currentUrl)) {
      // remove any outlet portions
      const currentUrlReg = currentUrl.split('/(')[0].split('?')[0];
      const pathReg = path.split('/(')[0];

      return RegExp(`${pathReg}$|${pathReg}/[0-9]+$|${pathReg}/access-request`).test(currentUrlReg);
    }
    return item.code === 'nest' && this.nestPanelExpanded;
  }
}
