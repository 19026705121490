{
  "name": "locumsnest",
  "version": "v20.10.0",
  "license": "MIT",
  "scripts": {
    "snapshot-dev": "./.snapshot-dev",
    "ng": "nx",
    "nx": "nx",
    "yarn": "ng config -g cli.packageManager yarn",
    "start": "node --max_old_space_size=8048 ./node_modules/.bin/nx serve --project=hospital-admin",
    "start:we-pledge": "node --max_old_space_size=8048 ./node_modules/.bin/nx serve --hmr --project=we-pledge --host 0.0.0.0",
    "start:tools": "node --max_old_space_size=8048 ./node_modules/.bin/nx run hospital-admin:serve --configuration=tools",
    "start:debug-prod": "node --max_old_space_size=8048 ./node_modules/.bin/nx run hospital-admin:serve --configuration=debug-prod",
    "start:debug-qa": "node --max_old_space_size=8048 ./node_modules/.bin/nx run hospital-admin:serve --configuration=debug-qa",
    "start:debug-demo": "node --max_old_space_size=8048 ./node_modules/.bin/nx run hospital-admin:serve --configuration=debug-demo",
    "start:debug-dev": "node --max_old_space_size=8048 ./node_modules/.bin/nx run hospital-admin:serve --configuration=debug-dev",
    "start:debug-pre-prod": "node --max_old_space_size=8048 ./node_modules/.bin/nx run hospital-admin:serve --configuration=debug-pre-prod",
    "start:debug-lab": "node --max_old_space_size=8048 ./node_modules/.bin/nx run hospital-admin:serve --configuration=debug-lab",
    "build": "node --max_old_space_size=8048 ./node_modules/.bin/nx build",
    "build:prod:we-pledge": "node --max_old_space_size=8048 ./node_modules/.bin/nx build --project=we-pledge --configuration production",
    "build:prod:hospital-admin": "yarn build:prod",
    "build:dev": "node --max_old_space_size=8048 ./node_modules/.bin/nx build --project=hospital-admin --configuration=dev",
    "build:prod": "node --max_old_space_size=8048 ./node_modules/.bin/nx build --project=hospital-admin --configuration production",
    "build:qa:hospital-admin": "node --max_old_space_size=8048 ./node_modules/.bin/nx build --project=hospital-admin --configuration=qa",
    "deploy:preprod": "yarn build:prod && ./deploy pre-prod",
    "deploy:staging": "yarn build:prod && ./deploy staging",
    "deploy:migration": "yarn build:prod && ./deploy migration",
    "deploy:dev": "yarn build:dev && ./deploy dev",
    "test": "nx test",
    "lint": "./node_modules/.bin/nx workspace-lint && ng lint",
    "lint:changed": ". ./.scripts && lint_changed",
    "elect:nightly": ". ./.scripts && elect_nightly",
    "compile:changed": ". ./.scripts && compile_changed",
    "e2e": "ng e2e",
    "affected:apps": "nx affected:apps",
    "affected:build": "nx affected:build",
    "affected:e2e": "nx affected:e2e",
    "affected:test": "nx affected:test",
    "affected:lint": "nx affected:lint",
    "affected:dep-graph": "nx affected:dep-graph",
    "nxformat": "nx format:write",
    "format": "prettier '**/*' --write --ignore-unknown",
    "format:changed": ". ./.scripts && format_changed",
    "format:write": "nx format:write",
    "format:check": "nx format:check",
    "update": "nx migrate latest",
    "update:check": "nx update:check",
    "update:skip": "nx update:skip",
    "dep-graph": "nx dep-graph",
    "help": "nx help",
    "workspace-generator": "nx workspace-generator",
    "release": ". ./.scripts && release_sentry ",
    "yarn:set:stable": "yarn set version 1.22.19"
  },
  "scarfSettings": {
    "enabled": false
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "17.1.2",
    "@angular/cdk": "17.1.2",
    "@angular/common": "17.1.2",
    "@angular/compiler": "17.1.2",
    "@angular/core": "17.1.2",
    "@angular/fire": "^17.0.1",
    "@angular/forms": "17.1.2",
    "@angular/localize": "17.1.2",
    "@angular/platform-browser": "17.1.2",
    "@angular/platform-browser-dynamic": "17.1.2",
    "@angular/router": "17.1.2",
    "@chamaeleonidae/chmln": "^1.0.1",
    "@ckeditor/ckeditor5-angular": "7.0.1",
    "@ckeditor/ckeditor5-core": "^41.1.0",
    "@ckeditor/ckeditor5-engine": "^41.1.0",
    "@ckeditor/ckeditor5-utils": "^41.1.0",
    "@ckeditor/ckeditor5-watchdog": "^41.1.0",
    "@maskito/angular": "^3.0.1",
    "@maskito/core": "^3.0.1",
    "@maskito/kit": "^3.0.1",
    "@ng-bootstrap/ng-bootstrap": "16.0.0",
    "@ng-select/ng-select": "12.0.7",
    "@ngneat/helipopper": "^9.2.1",
    "@ngneat/overview": "^6.0.0",
    "@ngrx/effects": "17.0.1",
    "@ngrx/entity": "17.0.1",
    "@ngrx/router-store": "17.0.1",
    "@ngrx/store": "17.0.1",
    "@ngrx/store-devtools": "17.0.1",
    "@nx/angular": "18.0.7",
    "@nx/devkit": "18.0.7",
    "@popperjs/core": "^2.11.8",
    "@sentry/angular-ivy": "^7.101.1",
    "@swc/helpers": "0.5.6",
    "angular-file": "^4.0.2",
    "apexcharts": "^3.49.1",
    "awesome-phonenumber": "^6.4.0",
    "bootstrap": "^5.3.2",
    "calendar": "^0.1.1",
    "chroma-js": "^2.4.2",
    "core-js": "^3.35.1",
    "file-saver": "^2.0.5",
    "immutable": "4.0.0-rc.12",
    "js-video-url-parser": "^0.5.1",
    "ln-ckeditor5": "https://s3.eu-west-2.amazonaws.com/builds.locumsnest.co.uk/ln-ckeditor5/ln-ckeditor5-v0.0.1.tgz",
    "lodash-es": "^4.17.21",
    "moment-range": "^4.0.2",
    "moment-timezone": "^0.5.45",
    "ng-apexcharts": "^1.10.0",
    "ng-recaptcha": "13.2.1",
    "ngrx-forms": "^8.0.0",
    "ngx-cookie-service": "17.1.0",
    "ngx-drag-scroll": "^17.0.1",
    "ngx-infinite-scroll": "17.0.0",
    "ngx-nested-ellipsis": "^2.1.4",
    "papaparse": "^5.4.1",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "rxjs": "~7.8.1",
    "sass": "^1.71.0",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "zone.js": "0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.1.2",
    "@angular-devkit/core": "17.1.2",
    "@angular-devkit/schematics": "17.1.2",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "~17.1.0",
    "@angular/compiler-cli": "17.1.2",
    "@angular/language-service": "17.1.2",
    "@ianvs/prettier-plugin-sort-imports": "^4.1.1",
    "@ngrx/eslint-plugin": "17.1.0",
    "@ngrx/schematics": "17.1.0",
    "@nx/eslint": "18.0.7",
    "@nx/eslint-plugin": "18.0.7",
    "@nx/jest": "18.0.7",
    "@nx/js": "18.0.7",
    "@nx/plugin": "18.0.7",
    "@nx/storybook": "18.0.7",
    "@nx/web": "18.0.7",
    "@nx/workspace": "18.0.7",
    "@schematics/angular": "17.1.2",
    "@sentry/cli": "^2.28.0",
    "@storybook/addon-essentials": "7.6.15",
    "@storybook/addon-interactions": "^7.5.3",
    "@storybook/angular": "7.6.15",
    "@storybook/core-server": "7.6.15",
    "@storybook/jest": "^0.2.3",
    "@storybook/test-runner": "^0.13.0",
    "@storybook/testing-library": "^0.2.2",
    "@swc-node/register": "1.8.0",
    "@swc/cli": "~0.1.62",
    "@swc/core": "1.3.88",
    "@types/chroma-js": "^2.4.4",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/jest": "29.5.12",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "20.11.16",
    "@types/uuid": "^9.0.8",
    "@typescript-eslint/eslint-plugin": "6.21.0",
    "@typescript-eslint/parser": "6.21.0",
    "dotenv": "16.4.5",
    "eslint": "8.56.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-cypress": "^2.15.1",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsdoc": "^48.0.6",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-unused-imports": "^4.0.0",
    "jasmine-core": "5.1.1",
    "jasmine-marbles": "^0.9.2",
    "jasmine-spec-reporter": "7.0.0",
    "jest": "29.4.3",
    "jest-environment-jsdom": "29.4.3",
    "jest-preset-angular": "13.1.6",
    "jsonc-eslint-parser": "^2.1.0",
    "nx": "18.0.7",
    "postcss-svg": "^3.0.0",
    "prettier": "3.2.5",
    "prettier-plugin-organize-attributes": "^1.0.0",
    "ts-jest": "29.1.2",
    "ts-node": "10.9.2",
    "tsc-files": "^1.1.4",
    "typescript": "5.3.3"
  }
}
